/* global VXConfig */
import Flux                   from '../../../flux/Flux';
import MobileRoutes           from '../Router/MobileRoutes';
import Translations           from '../../../utils/Translations';
import {getCommonTranslation} from '../../../utils/TranslationHelper';
import {VXPay}                            from '../../../utils/VXPay';
import {getFeatureHint, isFeatureEnabled} from '../../../utils/CommonUtils.js';

function toggleMenu(menuId) {
	Flux.VXMobile.toggleMenu(menuId);
}

function onChargeClick(event) {
	event.preventDefault();
	event.stopPropagation();
	VXPay.openPaytour();
}

function onSettingsClick(event) {
	event.preventDefault();
	VXPay.openSettings();
}

function onMyVisitXClick(event) {
	event.preventDefault();
	toggleMenu(Flux.Constants.VXMobile.Menus.MY_VISIT_X);
}


function isNavbarCamItemActive(currentPath) {
	return getCamsSecondNavbarItems().find((item) => currentPath.startsWith(item.link));
}

function getNavbarItems({countUnreadMessages, isLoggedIn, menus, currentPath}) {
	const isOneMenuOpen  = (menus.mainMenu || menus.myVisitX);
	const isMyVisitXPath = currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_GUEST_PROFILE)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_PRIVATE_COLLECTION)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_VIDEOS_PRIVATE_COLLECTION)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_PHOTOS_PRIVATE_COLLECTION)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_GUEST_PROFILE)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.BOUGHT_CONTENT_OVERVIEW)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_BOUGHT_PHOTOS)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_BOUGHT_VIDEOS)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_PINBOARD_PHOTOS)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_PINBOARD_PHOTOS_ALBUM)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_PHOTOS_GIFT)) ||
		currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MYVISITX_PINBOARD_VIDEOS));

	const {cams, videos, feed, myVisitX, messenger} = VXConfig.navbarConfig;

	const navbarItems = [];
	cams && navbarItems.push({
		text:       Translations.get('NavbarLabelModels'),
		icon:       '-icon-webcam-single-full',
		count:      0,
		link:       MobileRoutes.getLink(MobileRoutes.CAMS),
		selected:   isNavbarCamItemActive(currentPath) && !isOneMenuOpen,
		gaTracking: 'cams',
	});

	videos && navbarItems.push({
		text:       Translations.get('NavbarLabelVideos'),
		icon:       '-icon-mediathek',
		count:      0,
		link:       MobileRoutes.getLink(MobileRoutes.VIDEOS_INDEX),
		selected:   currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.VIDEOS_INDEX)) && !isOneMenuOpen,
		gaTracking: 'videos',
	});
	feed && navbarItems.push({
		text:       getCommonTranslation('explore'),
		icon:       '-icon-compass',
		count:      0,
		link:       MobileRoutes.getLink(MobileRoutes.FEED),
		selected:   currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.FEED)),
		gaTracking: 'feed',
	});
	messenger && navbarItems.push({
		text:     Translations.get('Messages'),
		icon:     '-icon-chat-flat',
		count:    countUnreadMessages,
		link:     MobileRoutes.getLink(MobileRoutes.MESSENGER),
		selected: currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.MESSENGER)) && !isOneMenuOpen,
		hide:     !isLoggedIn,
	});
	myVisitX && navbarItems.push({
		text:     Flux.Browser.isSexole() ? 'Mi Sexole' : Translations.get('MainNavMyVisitX'),
		icon:     '-icon-user-full',
		count:    0,
		link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_GUEST_PROFILE),
		selected: (isMyVisitXPath || menus.myVisitX) && !menus.mainMenu,
		callback: onMyVisitXClick,
	});

	return navbarItems;
}

function getMyVisitXMenuItems(guest) {
	const menuItems = [
		{
			name:           Translations.get('NavbarGuestMenuBalance') + ' ' + guest.balanceFormatted,
			buttonText:     Translations.get('MessengerChargeNow'),
			buttonCallback: onChargeClick,
			callback:       onChargeClick,
			link:           MobileRoutes.getLink(MobileRoutes.MYVISITX),
			gaTracking:     'name',
		},
		{
			name:       Translations.get('myvisitx_benefits.menu-entry'),
			link:       MobileRoutes.getLink(MobileRoutes.MYVISITX_BENEFITS),
			gaTracking: 'benefits',
		},
		isFeatureEnabled('mediaLibrary') ? {
			name:       'Mediathek' + getFeatureHint('mediaLibrary'),
			link:       MobileRoutes.getLink(MobileRoutes.MYVISITX_MEDIATHEK),
			gaTracking: 'mediathek',
		} : null,
		{
			name:       Translations.get('NavbarGuestMenuMyVideos'),
			value:      guest.boughtVideosCount,
			link:       MobileRoutes.getLink(MobileRoutes.MYVISITX_BOUGHT_VIDEOS),
			gaTracking: 'videos',
		},
		{
			name:       Translations.get('NavbarGuestMenuMyPhotos'),
			value:      guest.boughtPhotosCount,
			link:       MobileRoutes.getLink(MobileRoutes.MYVISITX_BOUGHT_PHOTOS),
			gaTracking: 'photos',
		},
		{
			name:       Translations.get('HeaderNaviMyFavorites'),
			value:      guest.pinnedFavoriteCount,
			link:       MobileRoutes.getLink(MobileRoutes.MYVISITX_FAVORITE_ACTORS),
			special:    `${guest.pinnedFavoriteOnlineCount} Online`,
			gaTracking: 'favorite',
		},
		{
			name:       Translations.get('MyVideoChatsHeadline'),
			value:      guest.videochatCount,
			link:       MobileRoutes.getLink(MobileRoutes.MYVISITX_MY_VIDEOCHATS),
			gaTracking: 'messenger',
		},
		{
			name:       Translations.get('navigation.navbar_spinwheel'),
			link:       MobileRoutes.getLink(MobileRoutes.MYVISITX_SPINWHEEL),
			gaTracking: 'spinwheel',
		},
		{
			name:       Translations.get('NavbarGuestMenuSettings'),
			link:       MobileRoutes.getLink(MobileRoutes.MYVISITX),
			callback:   onSettingsClick,
			gaTracking: 'settings',
		},
		{
			name:       Translations.get('Profile'),
			link:       MobileRoutes.getLink(MobileRoutes.MYVISITX_GUEST_PROFILE),
			gaTracking: 'profile',
		},
	].filter((item) => Boolean(item));

	if (VXConfig.showPrivateCollectionLink) {
		menuItems.splice(3, 0, {
			name:       Translations.get('NavbarGuestMenuPrivateCollection'),
			value:      guest.privateCollectionCount,
			link:       MobileRoutes.getLink(MobileRoutes.MYVISITX_PRIVATE_COLLECTION),
			gaTracking: 'private-collection',
		});
	}

	return menuItems;
}

function onVoiceCallClick() {
	if (VXConfig && VXConfig.vxPay && VXConfig.vxPay.externalRefId) {
		VXPay.openVoicecall();
		VXPay.onVoiceCallSuccess(() => {
			Flux.VXMobile.toggleMenu(Flux.Constants.VXMobile.Menus.NAVI);
		});
	} else {
		window.location.href = '/?jump=voicecallreload';
	}
}

/*function openInNewTab(url) {
	window.open(url, '_blank');
}*/

function getMenuItems() {
	const {tv, specials, voiceCall, vipClub} = VXConfig.navbarConfig;

	const menuItems = [];

	if (tv) {
		const tvItem = {
			name:    Translations.get('NavbarLabelTV'), // Television
			link:    MobileRoutes.getLink(MobileRoutes.TV_LIVESTREAM),
			subMenu: []
		};

		tv.showLivestream && tvItem.subMenu.push({
			name: Translations.get('MainNavLivestream'),
			link: MobileRoutes.getLink(MobileRoutes.TV_LIVESTREAM),
		});
		tv.showPremium && tvItem.subMenu.push({
			name: Translations.get('MainNavPremiumTV'),
			link: MobileRoutes.getLink(MobileRoutes.TV_PREMIUM),
		});
		tv.showMedia && tvItem.subMenu.push({
			name: Translations.get('MainNavMedia'),
			link: MobileRoutes.getLink(MobileRoutes.TV_MEDIA),
		});
		tv.showSchedule && tvItem.subMenu.push({
			name: Translations.get('MainNavScheduleMobile'),
			link: MobileRoutes.getLink(MobileRoutes.TV_PROGRAM),
		});
		tv.showTvGirls && tvItem.subMenu.push({
			name: Translations.get('MainNavTvGirls'),
			link: MobileRoutes.getLink(MobileRoutes.TV_GIRLS),
		});
		tv.showLateNight && tvItem.subMenu.push({
			name: Translations.get('MainNavLateNight'),
			link: MobileRoutes.getLink(MobileRoutes.TV_LATE_NIGHT),
		});
		menuItems.push(tvItem);
	}

	if (specials) {
		const specialsItem = {
			name:    Translations.get('navigation.navbar_specials'), // Aktionen
			link:    MobileRoutes.getLink(MobileRoutes.MAGAZINE),
			subMenu: []
		};

		specials.showNews && specialsItem.subMenu.push({
			name: Translations.get('navigation.navbar_news'), // Blog
			link: MobileRoutes.getLink(MobileRoutes.MAGAZINE),
		});

		specials.showContests && specialsItem.subMenu.push({
			name: Translations.get('navigation.navbar_contests'), // Wettbewerbe
			link: MobileRoutes.getLink(MobileRoutes.CONTESTS_OVERVIEW),
		});

		specials.showDailyDeal && specialsItem.subMenu.push({
			name: Translations.get('DailyBonus'), // Deal des Tages
			link: MobileRoutes.getLink(MobileRoutes.DAILYBONUSPAGE),
		});

		menuItems.push(specialsItem);
	}

	if (voiceCall) {
		menuItems.push({
			name:     Translations.get('MainNavVoicecall'), // Telefonzugang
			callback: onVoiceCallClick,
			icon:     '-icon-phone-full',
		});

	}

	if (vipClub) {
		menuItems.push({
			name:  Translations.get('DailyBonusControllerGoToTheVipSubpage'), // VipClub
			link:  MobileRoutes.getLink(MobileRoutes.VIP_CLUB),
			icon:  '-icon-person-vip',
			isVip: true,
		});
	}

	return menuItems;
}

function getNotificationClassNameByType(type) {
	let className = '';
	switch (type) {
		case 27: // AVS rejected
		case 16: // E-mail bounce
			className = '-icon-round-warning-line h-color-highlight';
			break;
		case 1: // Email address verification needed
		case 8: // AVS recommended
		case 26: // AVS pending
		case 10: // balance low
		case 11: // Messages importing
		case 22: // guest profile not completed
		case 17: // remaining limit
		case 32: // feed released
			className = '-icon-round-info-line h-color-special';
			break;
		case 24: // new photoalbum added
			className = '-icon-image-line h-color-fg';
			break;
		case 23: // new video added
			className = '-icon-videotake-line h-color-fg';
			break;
		case 5: // new favorite added
			className = '-icon-heart-line h-color-fg';
			break;
		case 4: // Textchat info
		case 20: // get gift
			className = '-icon-gift-line h-color-fg';
			break;
		case 13: // Album pinned
		case 14: // Picture pinned
		case 15: // Sedcards pinned
			className = '-icon-pin-line h-color-fg';
			break;
		case 6: // Private Shop Video bought
		case 7: // Private Shop Gallery bought
		case 25: // ticket bought
			className = '-icon-cart-line h-color-fg';
			break;
		case 2: // Email address verified
		case 9: // AVS completed
		case 12: // Messages imported
		case 18: // limit changed
		case 19: // payment success
		case 21: // password changed
		case 35:
			//reply
			className = '-icon-chat-text-line h-color-special';
			break;
		case 36:
			//mention
			className = '-icon-chatlist-line h-color-special';
			break;
		default:
			className = '-icon-round-success-line h-color-success';
			break;
	}

	return className;
}

function getSecondNavigation(currentPath, isLoggedIn) {
	let secondNavigation = {isHeadline: false, items: null, headline: null, hideBorder: false};

	//Special handling for Third Navigation Top Cams
	if (getCamsSecondNavbarItems(currentPath).find(item => currentPath.startsWith(item.link))) {
		secondNavigation = {...getFilteredItems(getCamsSecondNavbarItems(currentPath), currentPath)};
		return secondNavigation;
	}

	if (getBoughtVideoNavbarItems(currentPath).find(item => currentPath === item.link)) {
		secondNavigation.items = getBoughtVideoNavbarItems(currentPath);
		return secondNavigation;
	}

	if (getBoughtPhotoNavbarItems(currentPath).find(item => currentPath === item.link)) {
		secondNavigation.items = getBoughtPhotoNavbarItems(currentPath);
		return secondNavigation;
	}

	if (getCamsSecondNavbarItems(currentPath).find(item => currentPath === item.link)) {
		secondNavigation.items = getCamsSecondNavbarItems(currentPath);
		return secondNavigation;
	}

	if (getVideosSecondNavbarItems(currentPath).find(item => currentPath === item.link)) {
		secondNavigation = {...getFilteredItems(getVideosSecondNavbarItems(currentPath, isLoggedIn), currentPath)};
		return secondNavigation;
	}

	return null;

}

function getFilteredItems(items, currentPath) {
	const headline = items.find(item => (currentPath.startsWith(item.link) && item.thirdNavigation && item.thirdNavigation.find(child => currentPath === child.link)));

	if (headline) {
		return {isHeadline: true, items: [headline]};
	}

	return {items};
}

function getCamsSecondNavbarItems(currentPath) {
	const cams  = VXConfig.navbarConfig.cams;
	const items = [];

	if (cams.showOnline) {
		items.push(
			{
				text:       Translations.get('NavbarLabelModels'),
				isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.CAMS),
				link:       MobileRoutes.getLink(MobileRoutes.CAMS),
				isHeadline: false,
				gaTracking: 'cams',
			}
		);
	}

	if (cams.showCamShows) {
		items.push({
			text:       Translations.get('navigation.menu-item_cam-shows'),
			isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.CAM_SHOWS),
			link:       MobileRoutes.getLink(MobileRoutes.CAM_SHOWS),
			isHeadline: false,
			gaTracking: 'cams_camShows',
		});
	}

	if (cams.showCategories) {
		items.push({
			text:       Translations.get('NavbarLabelCategories'),
			isActive:   currentPath && currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.CATEGORIES_OVERVIEW)),
			link:       MobileRoutes.getLink(MobileRoutes.CATEGORIES_OVERVIEW),
			isHeadline: false,
			gaTracking: 'cams_categories',
		});
	}

	if (cams.showNewGirls) {
		items.push({
			text:       Translations.get('MainNavNewcomer'),
			isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.ACTOR_SEARCH_NEW_GIRLS),
			link:       MobileRoutes.getLink(MobileRoutes.ACTOR_SEARCH_NEW_GIRLS),
			isHeadline: false,
			gaTracking: 'cams_newgirls',
		});
	}

	if (cams.showNextDoor) {
		items.push({
			text:       Translations.get('MainNavCategoryCamsNextDoor'),
			isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.CATEGORIES_CAMS_NEXT_DOOR),
			link:       MobileRoutes.getLink(MobileRoutes.CATEGORIES_CAMS_NEXT_DOOR),
			isHeadline: false,
			gaTracking: 'cams_near',
		});
	}

	if (cams.showHDCams) {
		items.push({
				text:       Translations.get('CategoryOverviewHDCams'),
				isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.CATEGORIES_TOP_CAMS_HD_CAMS),
				link:       MobileRoutes.getLink(MobileRoutes.CATEGORIES_TOP_CAMS_HD_CAMS),
				isHeadline: false,
				gaTracking: 'cams_hd',
			}
		);
	}

	if (cams.showTopCams) {
		items.push({
			text:            Translations.get('MainNavTopCams'),
			isActive:        currentPath === MobileRoutes.getLink(MobileRoutes.CATEGORIES_TOP_CAMS),
			link:            MobileRoutes.getLink(MobileRoutes.CATEGORIES_TOP_CAMS),
			thirdNavigation: getTopCamsNavbar().NAVBAR_ITEMS,
			gaTracking:      'cams_top',
		});
	}

	return items;
}


function getTopCamsNavbar() {
	const TOP_100      = 'categoriesTopCamsContainer';
	const BEST_CHATS   = 'categoriesBestChatsContainer';
	const TOP_FAVORIRE = 'categoriesTopFavoritesContainer';
	const NAVBAR_ITEMS = [
		{
			text:         Translations.get('CategoryOverviewTop100'),
			link:         MobileRoutes.getLink(MobileRoutes.CATEGORIES_TOP_CAMS),
			id:           TOP_100,
			headlineType: Flux.Constants.Headlines.h1,
		},
		{
			text:         Translations.get('CategoryOverviewTopFavorites'),
			link:         MobileRoutes.getLink(MobileRoutes.CATEGORIES_TOP_CAMS_TOP_FAVORITES),
			id:           TOP_FAVORIRE,
			headlineType: Flux.Constants.Headlines.h1,
		},
		{
			text:         Translations.get('CollectionLabelTopChats'),
			link:         MobileRoutes.getLink(MobileRoutes.CATEGORIES_TOP_CAMS_BEST_CHATS),
			id:           BEST_CHATS,
			headlineType: Flux.Constants.Headlines.h1,
		},
	];
	return {TOP_100, BEST_CHATS, TOP_FAVORIRE, NAVBAR_ITEMS};
}

function getPastContestsNavbar(tabType) {
	return [
		{
			text:     Translations.get('ContestsAll'),
			id:       Flux.Constants.ContestLastContestsSubTypes.OVERVIEW,
			isActive: tabType === Flux.Constants.ContestLastContestsSubTypes.OVERVIEW,
		},
		{
			text:     Translations.get('ContestsVideoContests'),
			id:       Flux.Constants.ContestLastContestsSubTypes.VIDEO_CONTESTS,
			isActive: tabType === Flux.Constants.ContestLastContestsSubTypes.VIDEO_CONTESTS,
		},
		{
			text:     Translations.get('ContestsPhotoContests'),
			id:       Flux.Constants.ContestLastContestsSubTypes.PHOTO_CONTESTS,
			isActive: tabType === Flux.Constants.ContestLastContestsSubTypes.PHOTO_CONTESTS,
		},
	];
}

function getContestDetailNavbar(contestId, tabType) {
	const contestIdObj = {':contestId': contestId};
	//const actorNameObj = {':actorName': actorName};

	return [
		{
			text:     Translations.get('ContestsAll'),
			link:     MobileRoutes.getLink(MobileRoutes.CONTESTS_DETAILS, contestIdObj),
			id:       Flux.Constants.ContestSubTypes.OVERVIEW,
			isActive: tabType === Flux.Constants.ContestSubTypes.OVERVIEW,
		},
		{
			text:     Translations.get('ContestsTop10'),
			link:     MobileRoutes.getLink(MobileRoutes.CONTESTS_DETAILS_TOP10, contestIdObj),
			id:       Flux.Constants.ContestSubTypes.TOP_10,
			isActive: tabType === Flux.Constants.ContestSubTypes.TOP_10,
		},
		{
			text:     Translations.get('ContestsVoted'),
			link:     MobileRoutes.getLink(MobileRoutes.CONTESTS_DETAILS_VOTED, contestIdObj),
			id:       Flux.Constants.ContestSubTypes.VOTED,
			isActive: tabType === Flux.Constants.ContestSubTypes.VOTED,
		},
		{
			text:     Translations.get('ContestsUnvotedShort'),
			link:     MobileRoutes.getLink(MobileRoutes.CONTESTS_DETAILS_NOT_VOTED, contestIdObj),
			id:       Flux.Constants.ContestSubTypes.NOT_VOTED,
			isActive: tabType === Flux.Constants.ContestSubTypes.NOT_VOTED,
		},
		/*{
			text:     Translations.get('ContestsUnvotedShort'),
			link:     MobileRoutes.getLink(MobileRoutes.CONTESTS_DETAILS_ACTOR, {...contestIdObj, ...actorNameObj}),
			id:       'actor',
			isActive: MobileRoutes.getLink(MobileRoutes.CONTESTS_DETAILS_ACTOR, {...contestIdObj, ...actorNameObj}) === url,
		},*/
	];
}

function getPastContestDetailNavbar(contestId, tabType) {
	const contestIdObj = {':contestId': contestId};

	return [
		{
			text:     Translations.get('ContestsAll'),
			link:     MobileRoutes.getLink(MobileRoutes.CONTESTS_DETAILS, contestIdObj),
			id:       Flux.Constants.ContestSubTypes.OVERVIEW,
			isActive: tabType === Flux.Constants.ContestSubTypes.OVERVIEW,
		},
		{
			text:     Translations.get('ContestsTop10'),
			link:     MobileRoutes.getLink(MobileRoutes.CONTESTS_DETAILS_TOP10, contestIdObj),
			id:       Flux.Constants.ContestSubTypes.TOP_10,
			isActive: tabType === Flux.Constants.ContestSubTypes.TOP_10,
		},
		{
			text:     Translations.get('ContestsVoted'),
			link:     MobileRoutes.getLink(MobileRoutes.CONTESTS_DETAILS_VOTED, contestIdObj),
			id:       Flux.Constants.ContestSubTypes.VOTED,
			isActive: tabType === Flux.Constants.ContestSubTypes.VOTED,
		},
	];
}

function getSearchResultsNavbar(url, tabType, totalCountActors, totalCountVideos, totalCountPhotos, totalCountMediathek, totalCountMagazine) {
	const items = [
		{
			text:       Translations.get('CollectionSearchResultsActors'),
			link:       url,
			id:         Flux.Constants.SearchResults.Categories.actors.name,
			isActive:   tabType === Flux.Constants.SearchResults.Categories.actors.name,
			totalCount: totalCountActors,
		},
		{
			text:       Translations.get('CollectionSearchResultsVideos'),
			link:       url,
			id:         Flux.Constants.SearchResults.Categories.videos.name,
			isActive:   tabType === Flux.Constants.SearchResults.Categories.videos.name,
			totalCount: totalCountVideos,
		}
	];

	if (!Flux.Browser.isSexole()) {

		items.push({
			text:       Translations.get('CollectionSearchResultsMediathek'),
			link:       url,
			id:         Flux.Constants.SearchResults.Categories.tv.name,
			isActive:   tabType === Flux.Constants.SearchResults.Categories.tv.name,
			totalCount: totalCountMediathek,
		});
		items.push({
			text:       Translations.get('CollectionSearchResultsBlog'),
			link:       url,
			id:         Flux.Constants.SearchResults.Categories.blog.name,
			isActive:   tabType === Flux.Constants.SearchResults.Categories.blog.name,
			totalCount: totalCountMagazine,
		});
	}

	return items;
}

function getMyVisitXProfileNavbar() {
	const PICTURE     = 'myVisitXProvilePicture';
	const DATA        = 'myVisitXProvileData';
	const PREFERENCES = 'myVisitXProvilePreferences';

	const NAVBAR_ITEMS = [
		{
			text:     Translations.get('GuestProfilePictureUploadHeadline'),
			headline: Translations.get('GuestProfileAssistantProfilePicture'),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_GUEST_PROFILE),
			id:       PICTURE,
		},
		{
			text:     Translations.get('ActorLandingPageSignature') + ' ' + Translations.get('GuestProfileBasicPropertiesHeadline'),
			headline: Translations.get('GuestProfileBasicPropertiesHeadline'),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_GUEST_PROFILE_DATA),
			id:       DATA,
		},
		{
			text:     Translations.get('GuestProfileInterestsHeadline'),
			headline: Translations.get('GuestProfileInterestsHeadline'),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_GUEST_PROFILE_PREFS),
			id:       PREFERENCES,
		},
	];
	return {PICTURE, DATA, PREFERENCES, NAVBAR_ITEMS};
}

function getBoughtVideoNavbarItems(currentPath) {
	const items = [
		{
			text:     Translations.get('NavbarLabelBought'),
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.MYVISITX_BOUGHT_VIDEOS),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_BOUGHT_VIDEOS),
		},
		{
			text:     Translations.get('NavbarLabelVideosPinned'),
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.MYVISITX_PINBOARD_VIDEOS),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_PINBOARD_VIDEOS),
		},

		{
			text:     Translations.get('NavbarLabelVideosGifted'),
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.MYVISITX_VIDEOS_GIFT),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_VIDEOS_GIFT),
		},
	];

	if (VXConfig.showPrivateCollectionLink) {
		items.push({
			text:     Translations.get('MediaPanelPrivateCollection'),
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.MYVISITX_VIDEOS_PRIVATE_COLLECTION),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_VIDEOS_PRIVATE_COLLECTION),
		});
	}

	if (VXConfig.showLimitedVideosLink) {
		items.push({
			text:     Translations.get('MediaPanelLimitedVideos'),
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.MYVISITX_VIDEOS_LIMITED),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_VIDEOS_LIMITED),
		});
	}

	return items;
}

function getBoughtPhotoNavbarItems(currentPath) {
	const items = [
		{
			text:     Translations.get('NavbarLabelBought'),
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.MYVISITX_BOUGHT_PHOTOS),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_BOUGHT_PHOTOS),
		},
		{
			text:     Translations.get('NavbarLabelPhotoAlbumsPinned'),
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.MYVISITX_PINBOARD_PHOTOS_ALBUM),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_PINBOARD_PHOTOS_ALBUM),
		},
		{
			text:     Translations.get('NavbarLabelPhotoAlbumsGifted'),
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.MYVISITX_PHOTOS_GIFT),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_PHOTOS_GIFT),
		}
	];

	if (VXConfig.showPrivateCollectionLink) {
		items.push({
			text:     Translations.get('MediaPanelPrivateCollection'),
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.MYVISITX_PHOTOS_PRIVATE_COLLECTION),
			link:     MobileRoutes.getLink(MobileRoutes.MYVISITX_PHOTOS_PRIVATE_COLLECTION),
		});
	}

	return items;
}

function getVideosSecondNavbarItems(currentPath) {
	const videos = VXConfig.navbarConfig.videos;
	const items  = [];

	if (videos.showVideoStation) {
		items.push({
			text:       Translations.get('SecondNavbarLabelVideos'),
			isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.VIDEOS_INDEX),
			link:       MobileRoutes.getLink(MobileRoutes.VIDEOS_INDEX),
			id:         0,
			gaTracking: 'videos',
		});

	}

	if (videos.showNewVideos) {
		items.push({
			text:       Translations.get('SecondNavbarLabelVideosNew'),
			isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.VIDEOS_NEW_CATEGORY),
			link:       MobileRoutes.getLink(MobileRoutes.VIDEOS_NEW_CATEGORY),
			id:         1,
			gaTracking: 'videos_new',
		});
	}

	if (videos.showVipVideos) {
		items.push({
			text:       Translations.get('SecondNavbarLabelVideosVip'),
			isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.VIP30),
			link:       MobileRoutes.getLink(MobileRoutes.VIP30),
			id:         2,
			gaTracking: 'videos_vip',
		});
	}

	if (videos.showVideoSale) {
		items.push({
			text:       Translations.get('SecondNavbarLabelVideosSale'),
			isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.VIDEOS_ON_SALE_CATEGORY),
			link:       MobileRoutes.getLink(MobileRoutes.VIDEOS_ON_SALE_CATEGORY),
			id:         3,
			gaTracking: 'videos_sale',
		});
	}

	if (videos.showVideoTrends) {
		items.push({
			text:       Translations.get('SecondNavbarLabelVideosTrends'),
			isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.VIDEOS_TRENDING_CATEGORY),
			link:       MobileRoutes.getLink(MobileRoutes.VIDEOS_TRENDING_CATEGORY),
			id:         4,
			gaTracking: 'videos_trends',
		});
	}

	if (videos.showFreeVideos) {
		items.push({
			text:       Translations.get('SecondNavbarLabelVideosFree'),
			isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.VIDEOS_FREE_CLIPS_CATEGORY),
			link:       MobileRoutes.getLink(MobileRoutes.VIDEOS_FREE_CLIPS_CATEGORY),
			id:         5,
			gaTracking: 'videos_free',
		});
	}

    if (videos.showAllVideos) {
		items.push({
			text:       Translations.get('AllVideos'),
			isActive:   currentPath === MobileRoutes.getLink(MobileRoutes.VIDEOS_ALL_CATEGORY),
			link:       MobileRoutes.getLink(MobileRoutes.VIDEOS_ALL_CATEGORY),
			id:         1,
			gaTracking: 'videos_all',
		});
	}


	return items;
}

function getModelNavbarItems(
	currentPath,
	actorName,
	videoCount,
	photosCount,
	showLiveShowTab = false,
	hasClassicContent = false,
	classicVideosCount = 0,
	isFeedAvailable,
	isTopCamsAvailable = false
) {
	const isFeed = isFeedAvailable;
	const items  = [
		{
			text:     Translations.get('ActorHeaderNaviAboutMe'),
			isActive: currentPath === (isFeed ? MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_ABOUT, {':actorName': actorName}) : MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_OVERVIEW, {':actorName': actorName})),
			link:     isFeed ? MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_ABOUT, {':actorName': actorName}) : MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_OVERVIEW, {':actorName': actorName}),
		},
		{
			text:     Translations.get('ActorHeaderNaviVideos'),
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_VIDEOS, {':actorName': actorName}),
			link:     MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_VIDEOS, {':actorName': actorName}),
			quantity: videoCount,
		}
	];

	if (isFeed) {
		items.splice(0, 0, {
			text:     'Feed',
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_OVERVIEW, {':actorName': actorName}),
			link:     MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_OVERVIEW, {':actorName': actorName}),
		});
	}

	if (hasClassicContent) {
		items.splice(2, 0, {
			text:     'Classic Videos',
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_VIDEOS_CLASSIC, {':actorName': actorName}),
			link:     MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_VIDEOS_CLASSIC, {':actorName': actorName}),
			quantity: classicVideosCount,
		});
	}

	if (showLiveShowTab) {
		items.splice(2, 0, {
			text:     Translations.get('LiveShow'),
			isActive: currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_LIVE_SHOW, {':actorName': actorName})),
			link:     MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_LIVE_SHOW, {':actorName': actorName}),
		});
	}

	if (isTopCamsAvailable) {
		let text = 'Top Camgirls';

		switch (actorName.toLowerCase()) {
			case 'lenifetisch':
				text = 'Lenis Girls';
				break;
			case 'lexyroxx':
				text = 'Lexys Girls';
				break;
			case 'lucy-cat':
				text = 'Lucys Girls';
                break;
			case 'liaengel':
				text = 'Lia Girls';
				break;
			case 'lanagrey':
				text = 'Lanas Girls';
				break;
		}

		items.push({
			text:     text,
			isActive: currentPath === MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_TOP_CAMS, {':actorName': actorName}),
			link:     MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_TOP_CAMS, {':actorName': actorName}),
		});
	}

	return items;
}

function hideNavbar() {
	const mobileContent = document.querySelector('.vxmobile-content'); // remove class
	mobileContent.classList.add("vxmobile-content--hide-navbar");
}

function showNavbar() {
	const mobileContent = document.querySelector('.vxmobile-content'); // remove class
	mobileContent.classList.remove("vxmobile-content--hide-navbar");

}

export {
	toggleMenu,
	getNavbarItems,
	getMenuItems,
	getMyVisitXMenuItems,
	getNotificationClassNameByType,
	getSecondNavigation,
	getTopCamsNavbar,
	getPastContestsNavbar,
	getModelNavbarItems,
	getMyVisitXProfileNavbar,
	hideNavbar,
	showNavbar,
	getContestDetailNavbar,
	getSearchResultsNavbar,
	getPastContestDetailNavbar,
};
