import React               from 'react';
import PropTypes           from 'prop-types';
import {formatLargeNumber} from "../../../utils/CommonUtils";

function Headline(props) {
	if (!props.title) {
		return null;
	}

	return (
		<div className={`vxheadline ${props.class}`}>
			<div className="vxheadline__line">
				<div>
					{React.createElement(
						props.titleType,
						{className: 'vxheadline__title', dangerouslySetInnerHTML: {__html: props.title}},
					)}
					{props.quantity && typeof props.quantity.number === 'number' && <span className="vxheadline__qty">/ {formatLargeNumber(props.quantity.number, 9999)} {props.quantity.text}</span>}
				</div>
				<div className="vxheadline__icon-list">
					{props.children && props.children}
				</div>
			</div>
			<div className="vxheadline__line">
				{props.additionalHeadline}
			</div>
		</div>
	);
}

Headline.propTypes = {
	title:    PropTypes.string,
	quantity: PropTypes.shape({
		number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		text:   PropTypes.string,
	}),
	children: PropTypes.node,
	class:    PropTypes.string,
	additionalHeadline: PropTypes.node,
	titleType:PropTypes.string,
};

Headline.defaultProps = {
	title:    "",
	quantity: null,
	children: null,
	class:    "",
	additionalHeadline: null,
	titleType: 'span',
};

export default Headline;

